export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '业绩评估',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/业绩评估.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/业绩评估.pdf")}`,
                path: "资管/业绩评估/咨询服务"
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=业绩评估`,
                path: "资管/业绩评估/成功案例"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业绩评估/数据库')}`,
                path: "资管/业绩评估/数据库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业绩评估/案例库')}`,
                path: "资管/业绩评估/案例库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业绩评估/问题库')}`,
                path: "资管/业绩评估/问题库"
            },
            {
                name: '业绩评估常见问题',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f6.资管%2f3.业绩评估%2f2.问题库—业绩评估%2f业绩评估常见问题.json`,
                path: "资管/业绩评估/问题库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业绩评估/工具库')}`,
                path: "资管/业绩评估/工具库"
            },
            {
                name: '尽管问',
                img: '/icons/尽管问_白.svg',
                background: 'black',
                url: `/datapage/尽管问`,
                path: "资管/业绩评估/工具库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}