export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '品牌引进',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/品牌引进.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/品牌引进.pdf")}`,
                path: "建设/品牌引进/咨询服务"
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=品牌引进`,
                path: "建设/品牌引进/成功案例"
            },
            {
                name: '共建主页',
                img: '/icons/共建主页.svg',
                background: 'black',
                url: `/datapage/${encodeURIComponent('内容文件/5.建设/2.品牌引进/1.共建主页—酒管品牌')}`,
                path: "建设/品牌引进/共建主页"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('品牌引进/数据库')}`,
                path: "建设/品牌引进/数据库"
            },
            {
                name: '在建待建',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2F5.建设%2F1.定位可研%2F4.5.在建待建清单%2F?target_c2=在建待建清单g`,
                path: "建设/品牌引进/数据库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('品牌引进/案例库')}`,
                path: "建设/品牌引进/案例库"
            },
            {
                name: '格兰云天国际',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/2.品牌引进/2.与众不同—酒管品牌/格兰云天国际.pdf")}`,
                path: "建设/品牌引进/案例库"
            },
            {
                name: '梦卓恩',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/2.品牌引进/2.与众不同—酒管品牌/梦卓恩.pdf")}`,
                path: "建设/品牌引进/案例库"
            },
            {
                name: '费尔蒙',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURI("/内容文件/5.建设/2.品牌引进/2.与众不同—酒管品牌/费尔蒙.pdf")}`,   
                path: "建设/品牌引进/案例库"
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('品牌引进/问题库')}`,
                path: "建设/品牌引进/问题库"
            },
            {
                name: '品牌引进常见问题',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2F5.建设%2F2.品牌引进%2F5.问题库—品牌引进%2F品牌引进常见问题.json`,
                path: "建设/品牌引进/问题库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('品牌引进/工具库')}`,
                path: "建设/品牌引进/工具库"
            },
            {
                name: '智能比选',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/智能比选`,
                path: "建设/品牌引进/工具库"
            },
            {
                name: '查酒店',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/查酒店`,
                path: "建设/品牌引进/工具库"
            },
            {
                name: '品牌墙',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/section/brand`,
                path: "建设/品牌引进/工具库"
            },
        ]
    }]
}