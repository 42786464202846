import {useUserInfoStore} from '@/stores/userInfoStore'


export default (instance:string) => {
    const userInfoStore = useUserInfoStore()
    const userInfo = userInfoStore.info as any


    const getPushUrl = () => {
        // return `/report-viewer?report_path=/华美数据/赋能项目/在建待建清单（五星）.json`
        if (userInfo["合作伙伴推送"]){
            return `/report-viewer?report_path=/华美数据/赋能项目/在建待建清单（${userInfo["合作伙伴推送"]}）.json`
        } else {
            return ""
        }
    }


    const getCobuildUrl = () => {
        // return `/report-viewer?report_path=/华美数据/赋能项目/在建待建清单（五星）.json`
        if (userInfo["合作伙伴推送"] == "设计"){
            return `/datapage/customPageDesigner?instance=${userInfo["单位"]}`
        }else if (userInfo["合作伙伴推送"] == "供应商"){
            return `/datapage/customPageSupplier?instance=${userInfo["单位"]}`
        }else if (userInfo["合作伙伴推送"] == "酒管"){
            return `/datapage/customPageBrand?instance=${userInfo["单位"]}`
        } else {
            return ""
        }
    }

    return [
        {
            slogan: '用项目流量和客户资源为伙伴赋能',
            pageTitle: '华美赋能链',
            pageTitleIcon: '/icons/华美云.svg',
            icons: [
                { 
                    name: '年报递送',
                    img: '/icons/年报递送.svg',
                    url: getPushUrl(),
                    path: "我的"
                },
                { 
                    name: '公号推广',
                    img: '/icons/公号推广.svg',
                    url:'',
            path: "我的"
                },
                { 
                    name: '共建主页',
                    img: '/icons/共建主页.svg',
                    url:getCobuildUrl(),
                    path: "我的"
                },
                { 
                    name: '在建待建',
                    img: '/icons/在建待建.svg',
                    url:`/datapage/内容文件%2F7.华美%2F3.5.华美数据%2F2.在建待建%2F`,
                    path: "我的"
                },
                { 
                    name: '业主培训',
                    img: '/icons/业主培训.svg',
                    url:'',
            path: "我的"
                },
                { 
                    name: '业主考察',
                    img: '/icons/业主考察.svg',
                    url:'',
            path: "我的"
                },
                { 
                    name: '',
                    img: '',
                },
                { 
                    name: '',
                    img: '',
                },
            ]
        },
        {
            slogan: '',
            pageTitle: '我的华美云',
            pageTitleIcon: '/icons/华美云.svg',
            icons: [
                { 
                    name: '关注酒店',
                    img: '/icons/关注酒店.svg',
                    url:'/instancePicker?c1=酒店&datakey=关注酒店',
            path: "我的"
                },
                { 
                    name: '关注城市',
                    img: '/icons/关注城市.svg',
                    url:'/instancePicker?c1=城市&datakey=关注城市',
            path: "我的"
                },
                { 
                    name: '关注品牌',
                    img: '/icons/关注品牌.svg',
                        url:'/instancePicker?c1=品牌&datakey=关注品牌',
            path: "我的"
                },
                { 
                    name: '关注集团',
                    img: '/icons/关注集团.svg',
                    url:'/instancePicker?c1=集团&datakey=关注集团',
            path: "我的"
                },
                { 
                    name: '华美顾问',
                    img: '/icons/华美顾问.svg',
                    url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/%E5%86%85%E5%AE%B9%E6%96%87%E4%BB%B6/7.%E5%8D%8E%E7%BE%8E/1.%E5%8D%8E%E7%BE%8E%E9%A1%BE%E9%97%AE/1.%E5%8D%8E%E7%BE%8E%E7%AE%80%E4%BB%8B/%E5%8D%8E%E7%BE%8E%E9%A1%BE%E9%97%AE%E9%9B%86%E5%9B%A2%E7%AE%80%E4%BB%8B2024%7B4d1d76fb898a603952ff39200bd5feeaf42c1626a7248c34409e65fe4d873818%7D.pdf")}`,
            path: "我的"
                },
                { 
                    name: '华美年报',
                    img: '/icons/华美年报.svg',
                    url:"/datapage/内容文件%2F7.华美%2F3.华美研究%2F1.华美年报%2F?target_c2=华美年报",
            path: "我的"
                },
                { 
                    name: '华美研究',
                    img: '/icons/华美研究.svg',
                    url: '/v3/内容文件%2F7.华美%2F3.华美研究%2F0.5.最新研究%2F?target_c2=最新研究',
            path: "我的"
                },
                { 
                    name: '行业报告',
                    img: '/icons/行业报告.svg',
            path: "我的"
                    
                },
            ]
        },
    ]
}