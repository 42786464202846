export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '我的华美云',
        pageTitleIcon: '/icons/华美云.svg',
        icons: [
        { 
            name: '我的项目',
            img: '/icons/我的项目.svg',
            url:'/v3/内容文件%2F1.项目%2F',
            path: "我的"
        },
        { 
            name: '',
            img: '',
            url:''
        },
        { 
            name: '',
            img: '',
            url:''
        },
        { 
            name: '',
            img: '',
            url:''
        },
        { 
            name: '关注城市',
            img: '/icons/我的城市.svg',
            url:'/instancePicker?c1=城市&datakey=我的城市',
            path: "我的"    
        },
        { 
            name: '北京',
            img: '/icons/文件.svg',
            url:'/startPage/city?instance=北京',
            path: "我的"
        },
        { 
            name: '上海',
            img: '/icons/文件.svg',
            url:'/startPage/city?instance=上海',
            path: "我的"
        },
        { 
            name: '深圳',
            img: '/icons/文件.svg',
            url:'/startPage/city?instance=深圳',
            path: "我的"
        },
        { 
            name: '关注品牌',
            img: '/icons/我的品牌.svg',
            url:'/instancePicker?c1=品牌&datakey=我的品牌',
            path: "我的"
        },
        { 
            name: '万豪',
            img: '/icons/文件.svg',
            url:'/startPage/brand?instance=万豪',
            path: "我的"
        },
        { 
            name: '柏悦',
            img: '/icons/文件.svg',
            url:'/startPage/brand?instance=柏悦',
            path: "我的"
        },
        { 
            name: '希尔顿',
            img: '/icons/文件.svg',
            url:'/startPage/brand?instance=希尔顿',
            path: "我的"
        },
        { 
            name: '关注研报',
            img: '/icons/关注研报.svg',
            url:'/instancePicker?c1=研报&datakey=关注研报',
            path: "我的"
        },
        { 
            name: '减少浴缸',
            img: '/icons/文件.svg',
            url:'/report-viewer?report_path=/内容文件/7.华美/3.华美研究/0.5.最新研究/5.减少浴缸.pdf',
            path: "我的"
        },
        { 
            name: '晚一天',
            img: '/icons/文件.svg',
            url:'/report-viewer?report_path=/内容文件/7.华美/3.华美研究/0.5.最新研究/4.晚一天.pdf',
            path: "我的"
        },
        { 
            name: '空酒店',
            img: '/icons/文件.svg',
            url:'/report-viewer?report_path=/内容文件/7.华美/3.华美研究/0.5.最新研究/3.空酒店.pdf',
            path: "我的"
        },
        { 
            name: '尽管问',
            img: '/icons/尽管问_黑.svg',
            url: '/v3/内容文件%2F7.华美%2F5.华美工具%2F1.尽管问%2F?target_c2=尽管问',
            path: "我的"
        },
        { 
            name: '',
            img: '',
            url:''
        },
        { 
            name: '',
            img: '',
            url:''
        },
        { 
            name: '',
            img: '',
            url:''
        },
    ]}]
}