export default (instance:string) => {

    const trendUrl = `/内容文件/3.品牌/2.趋势/${instance}.pdf`

    return [{
        slogan: '酒店品牌发展',
        pageTitle: '评估与展望 - '+instance,
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '大数据',
                img: '/icons/大数据.svg',
                background: 'black',
                url: `/datapage/displayBrand?instance=${instance}`,
                path: "品牌/大数据"
            },
            {
                name: '趋势分析',
                img: '/icons/趋势分析.svg',
                background: 'black',
                url: `/report-viewer?report_path=/内容文件/3.品牌/2.趋势/${instance}.pdf`,
                path: "品牌/趋势分析"
            },
            {
                name: '共建主页',
                img: '/icons/共建主页.svg',
                background: 'black',
                url: `/datapage/customPageBrand?menu=${encodeURIComponent('品牌/大数据')}&instance=${instance}`,
                path: "品牌/共建主页"
            },
            {
                name: '在建待建',
                img: '/icons/在建待建.svg',
                background: 'black',
                url: `/datapage/内容文件%2F3.品牌%2F5.展望%2F2.在建待建酒店清单%2F?menu=${encodeURIComponent('品牌/在建待建')}&instance=${instance}`,
                path: "品牌/在建待建"
            },
            {
                name: '时间线',
                img: '/icons/时间线.svg',
                background: 'black',
                url: ``,
                path: "品牌/时间线"
            },
            {
                name: '对比分析',
                img: '/icons/对比分析.svg',
                background: 'black',
                url: ``,
                path: "品牌/对比分析"
            },
            {
                name: 'ESG',
                img: '/icons/ESG.svg',
                background: 'black',
                url: `/datapage/内容文件%2F3.品牌%2F3.与众不同%2F4.品牌ESG%2F?menu=${encodeURIComponent('品牌/ESG')}&instance=${instance}`,
                path: "品牌/ESG"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            }
        ]
    }]
}