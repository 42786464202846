import { times } from 'lodash';
import {watch} from 'vue'

const defaultInfo = {
} as UserInfo

export const useUserInfoStore = definePiniaStore('userInfo', () => {

    let localInfo = {} as UserInfo
    try{
        localInfo = JSON.parse(process.client ? (localStorage.getItem('userInfo') || 'null' ): 'null') 
                            || {avatar:''};
    }catch(err){
        console.log("localInfo", err)
    }
    const localTimestamp = Number(process.client ? localStorage.getItem('login_timestamp') : 0) 
                            || 0;

    console.log('localStorage', localInfo); 
    
    const info = ref(defaultInfo);
    if(localInfo){
        info.value = localInfo
    }
    const timestamp = ref(0)


    watch(()=>info.value, ()=>{
        if(process.client){
            localStorage.setItem('userInfo', JSON.stringify(info.value))
        }
    }, {deep:true})
    watch(()=>timestamp.value, ()=>{
        if(process.client){
            localStorage.setItem('login_timestamp', JSON.stringify(timestamp.value))
        }
    }, {deep:true})

    if(
        localTimestamp == 0 ||(
        (Date.now() - localTimestamp) < 
        (24 * 60 * 60 * 1000))
    ){
        info.value = localInfo
        timestamp.value = localTimestamp
    }

    const isAdmin = computed(() => {
        console.log('tudingAdmin')
        try{
            return info.value.authorization == 'admin'
        }catch(err){
            return false
        }
    })

    const logOut = () => {
        info.value = defaultInfo
        timestamp.value = 0
    }

    return {
        info,
        timestamp,
        isAdmin,
        logOut
    }
})
