<template>
    <NuxtPage />
    <Modal style="height:80vh"/>
    <div class="footer">
        ©华美顾问2023  &nbsp;&nbsp;  图达科技提供技术支持  &nbsp;&nbsp; <span class="version_display">v{{ version }}</span> <a href="https://beian.miit.gov.cn/" target="_blank">ICP证：粤ICP备11083341号</a>
    </div>
    <!-- <PWAPromptWrapper/> -->
    <Watermark v-if="route.path != '/login'" style="top:50px" :text="getWatermarkText()" />
</template>

<script setup lang="ts">
import version from './version'
import tscroll from './utils/tscroll'
import {useIsMobileStore} from '~/stores/isMobileStore'

import {useUserInfoStore} from '@/stores/userInfoStore'

import {modal} from '@tian-libs/modal'

// import PWAPromptWrapper from './components/PWAPrompt/Wrapper.vue'

// import {afterLogin} from '~/pages/loginUtils/afterLogin'

import {getUserInfo} from '~/controllers/getUserInfo'

// init tFetch
onBeforeMount(async ()=>{
    try{
        // await tFetch('/api/ping',process.server ? {useFetch} :{useAsyncData})
        // console.log("tFetch inited")
    }catch(e){
        console.log("tFetch init err", e)
    }
})

const nav = ref('')

const route = useRoute()

const userInfoStore = useUserInfoStore()
const getWatermarkText = () => {
    if(userInfoStore.info){
        const phoneLast4 = userInfoStore.info.phone_number ? userInfoStore.info.phone_number.slice(-4) : ''
        return userInfoStore.info.name + ' ' + phoneLast4
    }
    else{
        return ''
    }
}

onMounted(()=>{
    const userInfo = useUserInfoStore()
    console.log("userInfo", userInfo.info)
})

const router = useRouter()
const init = async ()=>{
    console.log('version', version)
    try{

        const isMobileStore = useIsMobileStore()
        
        if(process.client){
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                isMobileStore.value = true
            }
            else{
                isMobileStore.value = false
            }
        }

        if(process.env.NODE_ENV == 'production'){
            // console.log = () => {}
        }
        
        if(process.client){
            nav.value = navigator.userAgent
        }
    }catch(e){
        console.log('mount err', e)
    }

    if(process.client){
        window.addEventListener('scroll', () => {
            tscroll.value = document.documentElement.scrollTop
        })
    }
    // afterLogin()
    const jwt = useCookie('jwt')
    console.log("jwt", jwt)
    if (jwt.value){
        try{
            const userInfoRes = await getUserInfo()
            console.log("userInfoRes", userInfoRes)
        }catch(e){
            console.log("userInfoRes err", e)
            modal.alert(
                '登录已过期，请重新登录',
                () => {
                    router.push('/login')
                }
            )
        }
    }else{
        console.log("no jwt")
        modal.alert(
            '登录已过期，请重新登录',
            () => {
                router.push('/login')
            }
        )
    }
}
await init()
</script>

<style scoped>
.footer{
    width: 100%;
    height: 50px;
    background-color: transparent;
    background: #000000;
    position: relative;
    color:rgb(177, 177, 177);
    line-height: 50px;
    font-size: 14px;
    font-weight: 200;
    text-align: center;;
    z-index: 10;
}


.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.version_display{
    color: #636363;
    position: absolute;
    right: 10px;
}
</style>

<style>

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input{
    border-radius: 15px;
    border-color: black;
}
.anticon{
    color:black
}

.grayoutlesshover {
    filter: grayscale(100%);
    transition: filter 0.1s ease-in-out;
}
.grayoutlesshover:hover {
    filter: grayscale(0%);
}
</style>

