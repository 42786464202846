export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '业主代表',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/业主代表.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/业主代表.pdf")}`,
                path: "资管/业主代表/咨询服务"
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=业主代表`,
                path: "资管/业主代表/成功案例"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',    
                url: `/browser?menu_path=${encodeURIComponent('业主代表/数据库')}`,
                path: "资管/业主代表/数据库"
            },
            {
                name: '业主代表工作指南',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURIComponent("/内容文件/6.资管/1.业主代表/1.业主代表工作指南/业主代表工作指南.pdf")}`,
                path: "资管/业主代表/数据库"
            },
            {
                name: '酒店业主代表调查2024',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURIComponent("/内容文件/6.资管/1.业主代表/4.业代调查/酒店业主代表调查2024.pdf")}`,
                path: "资管/业主代表/数据库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业主代表/案例库')}`,
                path: "资管/业主代表/案例库"
            },
            {
                name: '业主代表吐槽大会',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURIComponent("/内容文件/6.资管/1.业主代表/2.吐槽大会/业主代表吐槽大会.pdf")}`,
                path: "资管/业主代表/案例库"
            },
            {
                name: '无法无天',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/report-viewer?report_path=${encodeURIComponent("/内容文件/6.资管/1.业主代表/3.无法无天/无法无天.pdf")}`,
                path: "资管/业主代表/案例库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业主代表/问题库')}`,
                path: "资管/业主代表/工具库"
            },
            {
                name: '业主代表常见问题',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f6.资管%2f1.业主代表%2f6.问题库—业主代表%2f业主代表常见问题.json`,
                path: "资管/业主代表/问题库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('业主代表/工具库')}`,
                path: "资管/业主代表/工具库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}