export default (instance:string) => {
    return [{
        slogan: '华美服务 = 咨询服务 + 华美云 + 尽管问',
        pageTitle: '资产保值增值',
        pageTitleIcon: '',
        background_img: '/backgrounds/sunny.jpg',
        icons: [
            {
                name: '咨询服务',
                img: '/icons/资产保值增值.svg',
                background: 'black',
                url: `/report-viewer?skip_decoding=1&report_path=${encodeURI("https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/华美服务介绍/资产保值增值.pdf")}`,
                path: "资管/资产保值增值/咨询服务"
            },
            {
                name: '成功案例',
                img: '/icons/成功案例.svg',
                background: 'black',
                url: `/project-list?category=华美服务&instance=资产保值增值`,
                path: "资管/资产保值增值/成功案例"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '数据库',
                img: '/icons/数据库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('资产保值增值/数据库')}`,
                path: "资管/资产保值增值/数据库"
            },
            {
                name: '全球上市REITs',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f6.资管%2f6.资产价值%2f1.5.全球上市REITs%2f全球上市REITs.json`,
                path: "资管/资产保值增值/全球上市REITs"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '案例库',
                img: '/icons/案例库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('资产保值增值/案例库')}`,
                path: "资管/资产保值增值/案例库"
            },
            {
                name: '酒店资产证券化',
                img: '/icons/文件.svg',
                background: 'black',
                url: `/datapage/内容文件%2f6.资管%2f6.资产价值%2f1.酒店资产证券化%2f2024%20酒店资产证券化.pdf`,
                path: "资管/资产保值增值/案例库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '问题库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('资产保值增值/问题库')}`,
                path: "资管/资产保值增值/问题库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``,
                path: ""
            },
            {
                name: '工具库',
                img: '/icons/工具库.svg',
                background: 'black',
                url: `/browser?menu_path=${encodeURIComponent('资产保值增值/工具库')}`,
                path: "资管/资产保值增值/工具库"
            },
            {
                name: '尽管问',
                img: '/icons/尽管问_白.svg',
                background: 'black',
                url: `/datapage/尽管问`,
                path: "资管/资产保值增值/工具库"
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
            {
                name: '',
                img: '',
                background: 'black',
                url: ``
            },
        ]
    }]
}